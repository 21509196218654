.sourcesContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.sourcesContainer > div {
  margin: 0.25rem;
}
