.inputBox {
  background: none;
  border: 1px solid #f17b55;
  padding: 0.5rem;
  width: 100%;
  color: white;
  margin: 0.25rem 0 0.25rem 0;
}

@media screen and (max-width: 768px) {
  .inputBox {
    width: calc(100vw - 5rem);
  }
}
