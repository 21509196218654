.headerContainer {
  display: flex;
  /* padding: 2rem; */
  align-items: center;
  justify-content: space-between;
}

.nav {
  display: flex;
  flex-direction: row;
}

.nav p {
  color: rgb(160, 160, 160);
  margin-left: 3rem;
  letter-spacing: -0.04rem;
  font-size: 0.9rem;
  transition: 0.3s;
  cursor: pointer;
}

.nav p:hover {
  color: rgb(255, 255, 255);
}

.header {
  color: #f17b55;
  font-size: 3rem;
  letter-spacing: -0.2rem;
}

@media screen and (max-width: 768px) {
  .nav p {
    display: none;
  }

  .header {
    padding: 1rem 0 0 1rem;
  }
}
