.curationContainer {
  color: white;
  /* padding: 2rem; */
}

.allPosts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.allPosts > div {
  margin: 0.25rem;
}

.top {
  padding-bottom: 1rem;
  padding-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
}

.top:hover {
  color: #838383;
}

.loadButton {
  border: 1px solid #f17b55;
  border-radius: 2rem;
  color: #f17b55;
  height: 2rem;
  transition: 0.3s;
  cursor: pointer;
  background: none;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.loadButton:hover {
  background-color: #f17b55;
  color: black;
}

.subHeader {
  color: #6d6d6d;
}

.unApplied {
  color: #f17b55;
  text-align: left;
  font-weight: 600;
  display: block;
}

.deletePrompt {
  z-index: 9;
  background-color: #000000;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.deletePrompt label {
  color: white;
  letter-spacing: -0.05rem;
}

.deletePrompt button {
  border: 1px solid #f17b55;
  background: none;
  color: #f17b55;
  border-radius: 2rem;
  height: 2rem;
  width: 5rem;
  margin: 0.3rem;
}

@media screen and (max-width: 768px) {
  .applyBtn {
    display: flex;
    justify-content: center;
    margin: auto;
    width: calc(22rem);
  }

  .applyBtn button {
    width: 100%;
  }

  .unApplied {
    text-align: center;
  }
  .loneHeader {
    text-align: center;
  }
  .top {
    padding: 1rem;
  }

  .middle {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }

  .middle > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
}
