@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali&display=swap");

.cardContainer {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  height: 9rem;
  width: 10rem;
  letter-spacing: -0.06rem;
  color: black;
  background-color: #d1c7b2;
  border-radius: 3rem;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  margin: 0 1rem 1rem 0;
  line-height: 1.5rem;
}

.arrNum {
  font-size: 3rem;
}

.bn {
  font-family: "Noto Serif Bengali", serif;
  letter-spacing: 0 !important;
}

.cardContainer > div > a > label {
  cursor: pointer;
}

.cardContainer > div > a > label:hover {
  text-decoration: underline;
}

.yellow {
  background-color: #a9d96c;
}

.red {
  background-color: #f17b55;
}

.cardContainer label {
  background: none;
}

.cardContainer img {
  position: absolute;
  bottom: -0.2rem;
  left: 0;
  height: 12rem;
  width: 12rem;
  filter: brightness(40%) grayscale(100%);
  border-radius: 0 0 3rem 3rem;
}

.runRate {
  position: absolute;
  bottom: 3rem;
  left: 1rem;
  z-index: 1;
  font-size: 3rem;
  font-weight: 600;
  color: white;
}
.src {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 1;
  color: white;
}

.back {
  display: flex;
  flex-direction: column;
}

.back label {
  font-size: 2.6rem;
  line-height: 4.3rem;
  /* min-width: 5rem; */
  text-align: left;
}

.back span {
  font-size: 1rem;
  line-height: 1rem;
  color: #444444;
  margin-bottom: 0.9rem;
}

.back div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.back button {
  border: none;
  background: black;
  color: white;
  font-size: -0.5rem;
  border-radius: 2rem;
  width: 5rem;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    display: flex;
    height: calc(9rem / 1.2);
    width: calc(10rem / 1.15);
    border-radius: 1.5rem;
    font-size: 1.3rem;
    letter-spacing: -0.05rem;
    line-height: 1.3rem;
    margin: 0;
    margin-bottom: 0.1rem;
  }

  .cardContainer img {
    height: calc(12rem / 1.2);
    width: calc(12rem / 1.122);
    border-radius: 0 0 1.5rem 1.5rem;
  }

  .runRate {
    bottom: 2.2rem;
    font-size: 1.5rem;
  }

  .back {
    display: flex;
    flex-direction: column;
  }

  .back label {
    font-size: 2.1rem;
    line-height: 3.5rem;
    letter-spacing: -0.2rem;
  }

  .back span {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
    letter-spacing: -0.05rem;
  }
}
