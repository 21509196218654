.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login img {
  height: 7rem;
}

.login button {
  border: 1px solid #f17b55;
  border-radius: 2rem;
  color: #f17b55;
  height: 2rem;
  transition: 0.3s;
  cursor: pointer;
  background: none;
  margin: 0.2rem;
}

.login input {
  background: none;
  border: 1px solid #f17b55;
  padding: 0.5rem;
  width: 17rem;
  height: 1.8rem;
  color: white;
  margin: 0.35rem 0 0.35rem 0;
}

.login label {
  font-weight: 600;
  color: #f17b55;
}
