.btn {
  border: 1px solid #f17b55;
  border-radius: 2rem;
  color: #f17b55;
  height: 2rem;
  transition: 0.3s;
  cursor: pointer;
  background: none;
  margin: 0.2rem;
}

.active {
  color: black;
  background-color: #f17b55;
}

@media screen and (min-width: 769px) {
  .btn:hover {
    color: black;
    background-color: #f17b55;
  }
}
