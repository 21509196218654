.dropDown {
  background-color: black;
  border-color: #f17b55;
  color: rgba(255, 255, 255, 0.404);
  font-size: 0.8rem;
  letter-spacing: -0.03rem;
  margin: 0.25rem 0 0.25rem 0;
  width: calc(100% + 1rem);
}

.menu {
  border-color: #f17b55;
  font-size: 0.8rem;
  width: calc(100% + 1rem);
}

.menu > div {
  background-color: black !important;
  color: rgba(255, 255, 255, 0.253) !important;
}
.menu > div:hover {
  background-color: #f17b55 !important;
  color: black !important;
}

@media screen and (max-width: 768px) {
  .dropDown {
    width: calc(100vw - 4rem);
  }
}
