@font-face {
  font-family: "helvthin";
  src: url("../assets/fonts/Helvetica-Light-05.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Helveticaa";
  src: url("../assets/fonts/Helvetica-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaaReg";
  src: url("../assets/fonts/Helvetica.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

.designCreationContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.designContainerHolder {
  position: fixed;
  left: -300%;
}

.mainDesignContainer {
  width: 2048px;
  height: 2048px;
  position: relative;
  font-weight: 500;
}

.red {
  background-color: red !important;
}

.logoNT {
  position: absolute;
  bottom: 6rem;
  left: 6rem;
  object-fit: contain;
  height: 165px;
}

.headline {
  position: absolute;
  top: 4rem;
  left: 6rem;
  color: black;
  font-size: 136px;
  letter-spacing: -0.55rem;
  line-height: 165px;
}

.headline > p {
  font-weight: 300;
  font-family: "HelveticaaReg", sans-serif;
}

.spaceAfter {
  padding-right: 30px;
}

.headline span {
  font-family: "Helveticaa", sans-serif;
  background: #1041ff;
  box-shadow: inset 0px 10px 0px 0px white;
  color: white;
}

.newsImage {
  position: absolute;
  height: calc(2048px - 2048px / 3);
  width: 2048px;
  object-fit: cover;
  bottom: 0;
}

.greyScale {
  filter: grayscale(1) brightness(0.5);
}

.style2Div {
  position: absolute;
  height: calc(2048px - 2048px / 3);
  width: 2048px;
  bottom: 0;
  opacity: 0.5;
  background-color: rgb(255, 0, 0);
}

.highRed {
  filter: drop-shadow(3px 3px red) sepia(100%) drop-shadow(-3px -3px blue);
}

.newsCutoutImage {
  position: absolute;
  height: 1200px;
  width: 2048px;
  object-fit: contain;
  bottom: 0;
  z-index: 2;
}

.mainDesignContainer .src {
  font-family: "helvthin", sans-serif;
  font-weight: 100;
  color: black;
  position: absolute;
  font-size: 45px;
  top: 608px;
  left: 6rem;
  letter-spacing: -0.08rem;
}

.topBar {
  width: 2048px;
  height: calc(2048px / 3);
  background-color: white;
}

.rightContainer {
  margin-left: 2rem;
  width: 15rem;
}

.designCreationContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.default {
  filter: grayscale(1) brightness(0.2);
}

.imgContainer {
  width: 30rem;
  height: 30rem;
  border: 1px solid #f17b55;
}

.inputs {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .designCreationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .imgContainer {
    width: calc(100vw - 4rem);
    height: calc(100vw - 4rem);
    border: 1px solid #f17b55;
  }

  .rightContainer {
    width: calc(100vw - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
  }
}
