@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap");
* {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: black;
}

html {
  font-size: 16px;
}

.dot-list button {
  border: none !important;
  border-color: none !important;
}

.react-multi-carousel-dot--active button {
  background: #f17b55 !important;
}
:root {
  color-scheme: only light;
}

.react-multi-carousel-track > li {
  width: auto !important;
  margin: 0.25rem !important;
}

input[type="range"] {
  background: none;
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #f17b55;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #f17b55;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #f17b55;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f17b55;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #f17b55;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #f17b55;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #f17b55;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #f17b55;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
  background: #f17b55;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #f17b55;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: none;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #f17b55;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #f17b55;
}
