.mainContainer {
  display: flex;
  flex-direction: column;
  color: #6d6d6d;
  width: 100%;
}

.addItem {
  width: 15rem;
  height: 15rem;
  border: 1px solid #f17b55;
  color: #f17b55;
  margin: 0 1rem 0 0;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}

.addItem:hover {
  background-color: #f17b55;
  color: black;
}

.addItem svg {
  font-size: 5rem;
}
