div.onlineIndicator {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.2rem;
  margin-right: 0.4rem;

  background-color: #f17b55;
  border-radius: 50%;

  position: relative;
}
span.blink {
  display: block;
  width: 0.6rem;
  height: 0.6rem;

  background-color: #f17b55;
  opacity: 0.7;
  border-radius: 50%;

  animation: blink 1s linear infinite;
}

@keyframes blink {
  100% {
    transform: scale(3, 3);
    opacity: 0;
  }
}
